.custom-identicon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(45deg);
  gap: 4px;
}
.papertrader-season-icon-container {
  display: flex;
  align-items: center;

  .connector {
    width: 2px;
    height: 1px;
  }
  .papertrader-season-label {
    padding-left: 4px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    gap: 4px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //width: 90px;
  }
}