.tw-chart-controls-outer-container {
  display: flex;
  height: 100%;
  padding: 6px;
  max-height: 60px;
  align-items: center;
  position: relative;

  .tw-chart-settings-container {
    border-radius: 6px;
    position: absolute;
    min-width: 400px;
    top: 100%;
    z-index: 9999;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    display: flex;
    flex-direction: column;
    padding: 2px;
    gap: 8px;
    .tw-chart-settings-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px;
      justify-content: space-evenly;
    }
    .tw-chart-settings-wrapped-row {
      display: flex;
      flex-direction: row;
      //align-items: center;
      padding: 4px;
      gap: 4px;
      flex-wrap: wrap;
    }

    .tw-chart-indicator-config {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      .tw-chart-indicator-config-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        gap: 4px;
      }
      .tw-chart-indicator-config-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .tw-chart-indicator-config-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px;
          gap: 4px;
        }
      }
    }
  }
}

.tw-chart-settings-header {
  border: 1px solid transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 2px;
  gap: 6px;
}
