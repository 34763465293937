.trading-dashboard-page-container {
  font-size: smaller;
  .page-title-label {
    filter: grayscale(0%);
    animation: toGrayscale 2s ease-in-out 0.5s forwards;
    // animate grayscale
    font-size: larger;
    margin-left: auto;
    font-family: Montserrat-LightAlt;
    text-transform: uppercase;
  }
  @media screen and (min-width: 768px) {
    flex-direction: column;
  }

  .dashboard-upper {
    display: flex;
    min-height: 220px;
    max-height: 250px;
    width: 100%;
    display: inherit;
    gap: inherit;
    flex: 1;
    .dashboard-upper-column {
      display: flex;
      flex-direction: column;
      gap: inherit;
    }
  }
  .dashboard-middle {
    display: flex;
    min-height: 100px;
    max-height: 200px;
    width: 100%;
    display: inherit;
    gap: inherit;
  }
  .dashboard-lower {
    display: inherit;
    gap: inherit;
    width: 100%;
  }

  display: flex;
  height: 100vh;
  margin-left: 4rem;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0rem;
    flex-direction: column;
  }
  .card {
    transition: 0.2s ease-in-out;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 0.3rem;
    @media screen and (max-width: 768px) {
      padding: 0.5rem;
    }
    display: flex;
    border-radius: 4px;
    @media screen and (min-width: 768px) {
      &.detached-info-header {
        width: 100%;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
      }
    }
    // large screens

    &.dashboard-wallet-value-container {
      display: flex;
      align-items: center;
      min-width: 200px;
    }
    &.dashboard-wallet-value-chart-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      min-width: 1400px;
      &.middle {
        max-width: 100%;
        @media screen and (max-width: 950px) {
          display: flex;
        }
      }
      @media screen and (max-width: 2250px) {
        min-width: 1200px;
      }
      @media screen and (max-width: 2050px) {
        min-width: 600px;
      }
      @media screen and (max-width: 1950px) {
        min-width: 600px;
      }
      @media screen and (max-width: 1550px) {
        min-width: 200px;
      }
    }
    &.dashboard-control-container {
      flex: 1;
      min-width: 400px;
      @media screen and (max-width: 1248px) {
        min-width: 250px;
      }
      @media screen and (max-width: 948px) {
        flex: 1;
        min-width: none;
        max-width: none;
      }
    }
    &.dashboard-wallet-value-distribution-container {
      min-width: 200px;
    }
    &.dashboard-info-container {
      display: flex;
      flex-direction: column;
      min-width: 50px;
      @media screen and (min-width: 768px) {
        &.detached {
          height: 600px;
          max-height: 600px;
       
        }
      }
      &.toggled {
        min-width: 400px;
      }
    }
    &.dashboard-overview-container {
      flex: 1;
      flex-direction: column;
      .list-child-container {
        display: flex;
        flex: 1 1;
        overflow: hidden;
        gap: 5px;
      }
    }

    // small screens

    &.small-screen-header {
      height: 50px;
      margin-left: 4.5rem;
      display: flex;
      align-items: center;
    }
    &.small-screen {
      height: 100px;
      display: flex;
      align-items: center;
    }
    &.small-screen-bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: auto;
      min-height: 130px;
      .dashboard-bottom-buttons-container {
        display: flex;
        width: 100%;
        justify-content: center;
      }
      &.toggled {
        flex: 1;
      }
    }
    &.small-screen-overview {
      height: 100px;
      display: flex;
      align-items: center;
      flex: 1;
      &.focused {
        height: fit-content;
        flex: 0;
      }
    }
    &.small-screen-orders {
      height: 30px;
      display: flex;
      align-items: center;
      &.toggled {
        flex: 1;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

.common-orders {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  position: relative;
  @media screen and (min-width: 768px) {
    min-height: 600px;
    &.detached {
      height: 600px;
      max-height: 600px;
      padding-top: 6px;
      padding-left: 6px;
      padding-bottom: 20px;
      width: 98%;
    }
  }
  //height: 76vh;
  // mobile
  @media screen and (max-width: 768px) {
    max-height: 90%;
  }
  .common-orders-header-stats-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    min-height: 30px;
    width: 100%;
    gap: 20px;
    label {
      opacity: 0.7;
      transition: 0.2s ease all;
      &:hover {
        opacity: 1;
      }
      cursor: pointer;
    }

    .win-loss-bar-container {
      width: 100px;
      height: 10px;
      display: flex;
      cursor: pointer;

      .win-bar,
      .loss-bar {
        transition: 0.2s ease all;
        height: 100%;
        border-radius: 2px;
        display: flex;
        font-size: x-small;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }

    .order-stats-column {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
      .order-stats-row {
        transition: .2s ease all;
        width: 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        min-height: 30px;
        margin-bottom: 2px;
        &.sharpe-sortino-row {
          cursor: pointer;
          * {
            cursor: pointer;
          }
        }
        .orders-stats-main-header {
          margin-right: auto;
          padding-left: 2px;
          flex: 1;
        }
        .orders-stats-header {
          padding: 2px;
          padding-left: 5px;
          padding-right: 5px;
          &.disabled {
            opacity: 0.5;
            //add line-through
            text-decoration: line-through;
          }
        }
      }
    }

  }
  .common-orders-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
  }
  .common-orders-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: inherit;
    width: 100%;
  }
}

.compact-strategies-popup-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .compact-strategies-popup-header {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .compact-strategies-popup-body {
    height: 80%;
    font-size: smaller;
  }
}
.compact-loader-container,
.common-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;

  &.strategies-menu {
    min-width: 400px;
  }
}

.compact-loader-container {
  &.leaderboard-loader {
    min-height: 90lvh;
  }
}

.compact-backtest-button-container {
  width: fit-content;
  /* width: 33%; */
  border-radius: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /*   border-radius: 50%; */
  .compact-backtest-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.common-overview-header-outer-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  .common-overview-header-container {
    width: 100%;
    transition: 0.2s ease all;
    padding: 5px;
    font-size: larger;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    max-height: 60px;
    // medium screens
    @media screen and (max-width: 1368px) {
      font-size: smaller;
    }
    .overview-header-anchor-button {
      padding: 5px;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      display: flex;
      align-items: center;
      gap: 5px;
      transition: 0.2s ease all;
      .code-icon {
        transition: 0.3s ease all;
        transform: rotate(90deg);
        animation: rotate 0.5s ease-out none;
        &.toggled {
          transform: rotate(0deg);
        }
      }
      &:hover {
        .code-icon {
          transform: rotate(0deg);
        }
      }

      /* &.toggled {
        .code-icon {
          transform: rotate(0deg);
        }
      } */
    }

    .backtest-running-label-container {
      display: flex;
      gap: 5px;
      .spinning-icon-container {
        align-items: center;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        align-items: center;
        height: 25px;
      }
    }

    .included-currencies-overview-container {
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
      gap: 10px;

      .included-currency-overview-icon {
        cursor: pointer;
        opacity: 0.5;
        transition: 0.2s ease all;
        &:hover {
          opacity: 1;
          scale: 1.1;
        }
      }
    }
    .focused-currency-button {
      padding-top: 12px;
      padding-bottom: 12px;
      cursor: pointer;
      * {
        cursor: pointer;
      }
      border-radius: 2px;
      transition: 0.2s ease all;
      display: flex;
      width: fit-content;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: smaller;
    }
    .attached-strategy-container {
      display: flex;
      align-items: center;
      gap: 5px;
      position: relative;
      flex: 1;
      width: 10px; // why does this work?
      .attached-strategy-popup-container {
        display: flex;
      }
      .attached-strategy-name-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        .attached-strategy-name-container-column {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.common-trading-overview-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 100%;
  // medium screens
  @media screen and (max-width: 1582px) {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    //height: 90%;
    /* .common-market-cap-outer-pool-container {
     height: auto;
    } */
  }
  .common-market-cap-outer-pool-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .common-focused-currency-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 98%;
    .common-focused-currency-header-container {
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .common-dashboard-stonk-chart-container {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    //max-width: 70vw;
  }
}

.empty-pool-container {
  display: flex;
  flex-direction: column;
  max-width: 240px;
  padding-top: 10px;
  gap: 5px;
  align-items: center;
  align-self: center;
  flex: 1;
}

.common-market-cap-pool-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 99%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70lvh;
  /* @media screen and (max-width: 1582px) {
    max-height: 40lvh;
  } */
  // medium screens
  @media screen and (min-width: 1542px) {
    flex: 1;
  }
  &.empty-pool {
    min-width: fit-content;

    max-width: 250px;
  }
}

.dimmed-label {
  opacity: 0.69;
  font-size: smaller;
}

.common-orders-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 1px;
  flex: 1;
}

.order-timeline-item {
  border-radius: 50%;
  transition: 0.2s ease all;
  height: 1px;
  width: 4px;
  display: flex;
  align-self: center;
  margin-left: 2px;
  &.in-range {
    border-radius: 4px;
    width: 2px;
    height: 99%;
  }
}

.common-event-log-list,
.log-list,
.order-list {
  scroll-behavior: smooth;
  padding: 2px;
  border-radius: 5px;
  overflow-x: hidden;
}

.inner-order-item {
  width: 100%;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
  transition: 0.2s ease all;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 8px;
  opacity: 0.7;
  position: relative;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  .compact-change-column {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .date-price-column {
    flex-direction: column;
    display: flex;
    height: 30px;
    align-items: flex-end;
    justify-content: center;
    margin-left: auto;
    margin-right: 10px;
    &.hovering {
      position: absolute;
      transform: translateY(-55%);
      left: 25%;
      font-size: larger;
      user-select: none;
      transition: 0.2s ease all;
      opacity: 0;
    }
  }
  &:hover {
    .date-price-column {
      &.hovering {
        opacity: 1;
      }
    }
  }

  &.focused {
    opacity: 1;
    margin-left: 5px;
  }
}

.common-order-item-container {
  opacity: 0.7;
  cursor: pointer;
  * {
    cursor: pointer;
  }

  transition: 0.2s ease all;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
  .date-price-column {
    flex-direction: column;
    display: flex;
    height: 30px;
    align-items: flex-end;
    justify-content: center;
  }

  .order-item-stat-label-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 5px;
    height: 100%;
    padding: 5px;
    border-radius: 5px;
  }
  &.highlighted {
    opacity: 1;
    margin-left: 0;
    //medium screens
  }
}
.currency-array-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .logo-container {
    display: flex;
    justify-content: center;
    background-size: 50% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-blend-mode: darken;
    background-position-x: 50%;
    transition: ease all 0.2s;
  }
}
.pagination-bar {
  width: inherit;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.papertrader-list-child-container {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-height: 70lvh;
  .papertrader-user-strategies {
    min-width: 50%;
  }
}

.papertrader-leaderboard-parent {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
  max-height: 95%;
  gap: 15px;
  // media query for mobile
  @media (max-width: 768px) {
    max-height: 82lvh;
  }

  .papertrader-leaderboard-parent-header {
    border-radius: 10px;
    padding: 3px;
    padding-top: 10px;
    box-shadow:
      rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    display: flex;
    align-items: center;
    .label-container {
      display: flex;
      align-items: center;
      font-size: smaller;
      gap: 5px;
    }
    @media (max-width: 768px) {
      justify-content: space-evenly;
    }
  }
}

.focus-currency-menu-control {
  display: flex;
  height: 100%;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
  align-items: center;
  font-size: smaller;
  min-width: 70px;
  justify-content: space-evenly;
  opacity: 0.5;
  transition: 0.2s ease all;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &:hover {
    opacity: 1;
  }
}

.candle-size-control-container {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  align-self: center;
  gap: 4px;
  .candle-size-control-label {
    font-family: "BalooBhaijaan2";
    font-size: smaller;
    align-items: center;
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
  }
}

.trading-dashboard-strategies-overview {
  min-width: 600px;
}

.desktop-leaderboard-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  .dimmed-label {
    &.loading-label {
      font-size: larger;
    }
  }
}

.utc-clock {
  cursor: default;
  * {
    cursor: default;
  }
  transition: 0.2s ease all;
  display: flex;
  height: fit-content;
  margin-top: auto;
  font-size: small;
  width: 100%;
  min-width: 180px;
  padding: 5px;
  justify-content: center;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.wallet-value-chart-header,
.dashboard-info-header {
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 10px;
  gap: 5px;
}
.dashboard-info-header {
  margin-bottom: 10px;
  user-select: none;
  * {
    user-select: none;
  }
}

.header-button {
  transition: 0.2s ease all;
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px 1px 2px,
    rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px,
    rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px,
    rgba(0, 0, 0, 0.07) 0px 32px 64px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 99%;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  * {
    user-select: none;
  }

  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  &.info {
    min-width: fit-content;
    flex: 1;
  }
  &.disabled {
    opacity: 0.5 !important;
    cursor: default;
    * {
      cursor: default;
    }
    //add line-through
    //text-decoration: line-through;
  }

  &.active {
    opacity: 1;
  }
  &.utc-clock-contianer {
    cursor: default;
  }
  &.quick-start {
    gap: 5px;
  }
}

.compact-hide-orders-button {
  display: flex;
  width: 95%;
  margin-top: auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  label {
    margin-right: auto;
  }
}

.papertrader-compact-leaderboard-parent {
  width: 100%;
}
.compact-action-indicator {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-controls-container {
  display: flex;
  z-index: 98;
  flex: 1;
  width: 100%;
  align-items: center;
  gap: 5px;
}

#orders-header-button {
  flex: 1;
  max-width: 50%;
  width: 100%;
}

// media query for screens larger than 768px

@media screen and (min-width: 768px) {
  .detached-panel-popup-body {
    min-height: 100px;
    height: auto;
    max-height: 80lvh;
    font-size: smaller;
    &:focus,
    &:focus-visible {
      outline: none !important;
    }
  }
  .detached-panel-popup-inner {
    height: 95%;
  }

  .detached-panel-popup-container,
  .detached-panel-popup-body,
  .detached-panel-popup-wrapper {
    min-width: 440px;
    max-width: 800px;
    backdrop-filter: blur(83px);
    -webkit-backdrop-filter: blur(83px);

    background-color: transparent !important;
    * {
      background-color: transparent;
      &:focus,
      &:focus-visible {
        outline: none !important;
      }
    }
  }

  .detached-panel-popup-root {
    &.editor {
      margin: 2%;
    }
  }

  .detached-panel-popup-inner {
    height: 95%;
  }
}

.buy-order-item-highlight {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  position: relative;
  min-width: 150px;
  max-width: 150px;
  .left-chevron {
    position: absolute;
    left: 14%;
    top: 15;
  }
}

.sharpe-sortino-modal {
  display: flex;
  flex-direction: row;
  gap: 4px;
  .sharpe-sortino-container-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .sharpe-container, .sortino-container {
    width: 100%;
    padding: 6px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
  }
}

.sharpe-sortino-label {
  font-family: "BalooBhaijaan2";
}

.empty-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}