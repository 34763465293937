.strategy-header-component-container {
  width: 100%;
  display: flex;
  flex: 1;
  min-height: 130px;
  //max-height: 9vh;
  .strategy-header-component {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 4.5rem;
    gap: 0.5rem;
    // media queries
    @media screen and (max-width: 2315px) {
      flex-direction: column-reverse;
    }
  }
}
.strategy-header-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  //padding: 5px;
  &.public-header {
    min-width: 700px;
  }
}

.public-header-editor-outer-container {
  width: 99%;
  display: flex;
  flex-direction: column;
  .public-header-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    .pool-composition-switch {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.public-header-editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
  .public-header-row {
    width: 100%;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    display: flex;
    .public-header-info-tooltip {
      align-items: center;
      display: flex;
      margin-left: auto;
      margin-right: 0.5rem;
    }
    .dimmed-label {
      &.public-header-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 200px;
        max-width: 200px;
        // media queries
        @media screen and (max-width: 1368px) {
          min-width: 150px;
          max-width: 150px;
          font-size: small;
        }
      }
    }
    .slider-container {
      width: 100%;
    }
    .pool-display-container {
      width: 100%;
      min-height: 40px;
      max-height: 50px;
      display: flex;
      gap: 5px;
      position: relative;

      .pool-display {
        transition: 0.2s ease all;
        display: flex;
        border-radius: 6px;
        padding: 2px;
        overflow: hidden;
        position: relative;

        .pool-display-label-container {
          width: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          user-select: none;
          * {
            user-select: none;
          }
          .pool-display-label-column {
            display: flex;
            flex-direction: column;
            padding: 2px;
          }

          .pool-display-label-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }

  .currency-array-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 200px;
    gap: 15px;
  }
}

.fixed-currency-array-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  transition: 0.2s ease all;
  &:hover {
    z-index: 98;
    transform: scale(1.1);
  }
  //transform: translateY(20%);
  position: relative;

  .currency-array-item {
    cursor: pointer;
    border: 2px solid transparent;
    transition: 0.2s ease all;
    padding: 1px;
    border-radius: 50%;
    &.selected {
      transform: scale(1.2);
    }
    &:hover {
      z-index: 98;
      transform: scale(1.1);
    }
  }

  .fixed-currency-controls {
    transition: 0.2s ease all;
    //backdrop-filter: blur(5px);
    //--webkit-backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 100%;
    opacity: 0;
    position: absolute;

    .currency-shortcut-button {
      min-width: 85%;
      min-height: 20px;
      border-radius: 50%;
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }

    .fixed-currency-control-button {
      cursor: pointer;
      * {
        cursor: pointer;
      }
      opacity: 0.6;
      height: fit-content;
      width: fit-content;
      z-index: 97;
    }
  }
  &:hover {
    .fixed-currency-controls {
      transition-delay: 0.2s;
      opacity: 1;
    }
  }
}
.strategy-controls-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-evenly;
}

.common-button-column {
  width: fit-content;
  display: flex;
  flex-direction: column;
  .strategy-name-label {
    text-transform: capitalize;
    font-family: Montserrat-LightAlt;
    font-weight: 600;
    opacity: 1;
    font-size: smaller;
    padding: 3px;
  }
}
.strategy-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.strategy-button-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dimmed-label {
  &.strategy-card-header {
    font-size: 14px;
  }
}

.utility-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 5px;
}
.strategy-save-controls {
  margin-left: auto;
}

.versions-popup-container,
.versions-popup-body,
.versions-popup-wrapper {
  min-width: 500px;
  @media (max-width: 768px) {
    min-width: 93lvw;
    max-width: 93lvw;
  }
  backdrop-filter: blur(83px);
  -webkit-backdrop-filter: blur(83px);

  background-color: transparent !important;
  * {
    background-color: transparent;
  }
}
